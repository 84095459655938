import { DownloadIcon } from '@chakra-ui/icons';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Box,
  DrawerFooter,
  Button,
  VStack,
  Image,
  Center,
  useToast,
  Portal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  Modal,
  useDisclosure,
  Icon,
  ModalBody,
} from '@chakra-ui/react';
import { Api } from 'api';
import { LoadingText } from 'components';
import { expertiseSocket, getExpertiseBackroundImageUrl } from 'core';
import { useFormik } from 'formik';
import React, { useEffect, useMemo, useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  BodyPartAnswerInfo,
  BodyPartExpertiseInfo,
  CheckOldBodyDamagesResponse,
  ExpertiseBodyDamagePartType,
  // ExpertiseBodyDamageType,
  ExpertiseChassisCarBodyPartName,
  ExpertiseCarBodyTypes,
  ExpertiseChassisDamageType,
  VehicleQueryCacheKeys,
} from 'types';
// import { ExpertiseBodyworkExpertiseStateSelector } from './ExpertiseBodyworkExpertiseStateSelector';
import { ExpertiseBodyworkExpertiseChassisStateSelector } from './ExpertiseBodyworkExpertiseChassisStateSelector';

export interface ExpertiseBodyworkExpertiseChassisDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  carBodyType: ExpertiseCarBodyTypes;
  isDisabled?: boolean;
}

type ExpertisePartsData = {
  [key: number]: ExpertiseChassisDamageType;
};

type ExpertiseQuestionData = {
  [key: number]: string;
};

type ExpertiseSaveData = { expertises: ExpertisePartsData; answers: ExpertiseQuestionData };

type PositionLookupTableRow = {
  left?: string;
  bottom?: string;
  top?: string;
  right?: string;
};

type PositionLookupTable = {
  [key in ExpertiseChassisCarBodyPartName]: PositionLookupTableRow;
};

const genericPositionLookupTable: PositionLookupTable = {
  [ExpertiseChassisCarBodyPartName.SagOnSasi]: {
    left: '0%',
    top: '12%',
  },
  [ExpertiseChassisCarBodyPartName.SagPodye]: {
    left: '16%',
    top: '21%',
  },
  [ExpertiseChassisCarBodyPartName.SagOnDirek]: {
    left: '25%',
    top: '13%',
  },
  [ExpertiseChassisCarBodyPartName.SagUstDirek]: {
    left: '30%',
    top: '27%',
  },
  [ExpertiseChassisCarBodyPartName.SagOrtaDirek]: {
    left: '48%',
    top: '13%',
  },
  [ExpertiseChassisCarBodyPartName.SagArkaCamurlukKilitKarsiligi]: {
    left: '65%',
    top: '10%',
  },
  [ExpertiseChassisCarBodyPartName.SagArkaUstDirek]: {
    right: '20%',
    top: '25%',
  },

  [ExpertiseChassisCarBodyPartName.SagArkaSasi]: {
    right: '2%',
    top: '12%',
  },
  [ExpertiseChassisCarBodyPartName.OnPanel]: {
    left: '2%',
    top: '46%',
  },
  [ExpertiseChassisCarBodyPartName.BagajHavuzu]: {
    right: '12%',
    top: '46%',
  },
  [ExpertiseChassisCarBodyPartName.ArkaPanel]: {
    right: '1%',
    top: '46%',
  },
  [ExpertiseChassisCarBodyPartName.SolOnSasi]: {
    left: '0%',
    bottom: '12%',
  },
  [ExpertiseChassisCarBodyPartName.SolPodye]: {
    left: '16%',
    bottom: '21%',
  },
  [ExpertiseChassisCarBodyPartName.SolOnDirek]: {
    left: '25%',
    bottom: '13%',
  },
  [ExpertiseChassisCarBodyPartName.SolUstDirek]: {
    left: '30%',
    bottom: '27%',
  },
  [ExpertiseChassisCarBodyPartName.SolOrtaDirek]: {
    left: '48%',
    bottom: '13%',
  },
  [ExpertiseChassisCarBodyPartName.SolArkaCamurlukKilitKarsiligi]: {
    left: '65%',
    bottom: '10%',
  },
  [ExpertiseChassisCarBodyPartName.SolArkaUstDirek]: {
    right: '20%',
    bottom: '25%',
  },
  [ExpertiseChassisCarBodyPartName.SolArkaSasi]: {
    right: '2%',
    bottom: '12%',
  },
};

const VehicleTypePositionTable: {
  [key in ExpertiseCarBodyTypes]: PositionLookupTable;
} = {
  [ExpertiseCarBodyTypes.Sedan]: genericPositionLookupTable,
  [ExpertiseCarBodyTypes.Hatchback5]: genericPositionLookupTable,
  [ExpertiseCarBodyTypes.Hatchback2]: genericPositionLookupTable,
  [ExpertiseCarBodyTypes.Pickup]: genericPositionLookupTable,
  [ExpertiseCarBodyTypes.Pickup2]: genericPositionLookupTable,
  [ExpertiseCarBodyTypes.Minibus]: genericPositionLookupTable,
  [ExpertiseCarBodyTypes.Panelvan]: genericPositionLookupTable,
  [ExpertiseCarBodyTypes.CanliPanelvan]: genericPositionLookupTable,
  [ExpertiseCarBodyTypes.TekSurguPanelvan]: genericPositionLookupTable,
  [ExpertiseCarBodyTypes.SUV]: genericPositionLookupTable,
  [ExpertiseCarBodyTypes.Station]: genericPositionLookupTable,
};

export const ExpertiseBodyworkExpertiseChassisDrawer: React.FC<ExpertiseBodyworkExpertiseChassisDrawerProps> = ({
  isOpen,
  onClose,
  carBodyType,
  isDisabled,
}) => {
  const customer = useSelector((selector: RootState) => selector.customer.focusedCustomer);
  const inputPositionTable = useMemo(() => VehicleTypePositionTable[carBodyType], [carBodyType]);
  const toast = useToast();
  const [oldReportUrl, setOldReportUrl] = useState('');
  const {
    isOpen: checkOldDamagesModalIsOpen,
    onOpen: checkOldDamagesModalOnOpen,
    onClose: checkOldDamagesModalOnClose,
  } = useDisclosure();
  const {
    data: expertises,
    isLoading: isExpertisesLoading,
    refetch: refetchExpertises,
  } = useQuery(
    [VehicleQueryCacheKeys.GetChassisControl, customer!.id],
    async () => {
      const { data: expertiseResponse } = await Api.Expertise.getCustomerBodyPartsExpertise(
        customer!.id,
        ExpertiseBodyDamagePartType.Chassis,
      );
      const { expertiseBodyDamages, expertiseBodyAnswers } = expertiseResponse;

      return {
        expertises: expertiseBodyDamages.reduce((accum: any, nextValue: BodyPartExpertiseInfo) => {
          // eslint-disable-next-line no-param-reassign
          accum[nextValue.expertiseBodyDamagePartId] = nextValue.expertiseBodyDamageValue;
          return accum;
        }, {} as ExpertisePartsData),
        answers: expertiseBodyAnswers.reduce((accum: any, nextValue: BodyPartAnswerInfo) => {
          // eslint-disable-next-line no-param-reassign
          accum[nextValue.expertiseBodyQuestionId] = nextValue.answer;
          return accum;
        }, {} as ExpertiseQuestionData),
      };
    },
    {
      refetchOnWindowFocus: false,
      enabled: customer != null && isOpen,
      cacheTime: 0,
      onError: (err) => {
        console.error(err);
        toast({
          title: 'Boyalı Değişen verileri çekilirken hata oluştu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );

  const {
    data: expertiseTemplates,
    refetch: expertisesTemplateRefetch,
    isLoading: isExpertisesTemplateLoading,
  } = useQuery(
    VehicleQueryCacheKeys.GetChassisControlExpertiseTemplates,
    async () => {
      const response = await Api.Expertise.getExpertiseTemplate(
        customer!.customerVehicle!.vehicleTypeId,
        ExpertiseBodyDamagePartType.Chassis,
      );
      return response.data;
    },
    {
      refetchOnWindowFocus: false,
      enabled: customer != null && isOpen,
      onError: (err) => {
        console.error(err);
        toast({
          title: 'Boyalı değişen işlemleri için gereken verileri çekerken hata oluştu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      answers: (expertises?.answers || {}) as ExpertiseQuestionData,
      expertises: (expertises?.expertises || {}) as ExpertisePartsData,
    },
    onSubmit: async (data: ExpertiseSaveData) => {
      if (expertiseTemplates) {
        const filledExpertises = expertiseTemplates.expertiseBodyDamageParts.reduce((accum, next) => {
          // eslint-disable-next-line no-param-reassign
          accum[next.id] = data.expertises[next.id] || ExpertiseChassisDamageType.Original;
          return accum;
        }, {} as any);

        const filledAnswers = expertiseTemplates.expertiseBodyQuestions.reduce((accum, next) => {
          // eslint-disable-next-line no-param-reassign
          accum[next.id] = data.answers[next.id] || '0';
          return accum;
        }, {} as any);

        if (checkOldDamagesModalIsOpen) {
          checkOldDamagesModalOnClose();
          // eslint-disable-next-line no-use-before-define
          saveExpertiseMutate.mutateAsync({ expertises: filledExpertises, answers: filledAnswers });
        } else {
          // eslint-disable-next-line no-use-before-define
          const oldResults = await checkOldDamageMutate.mutateAsync({
            expertises: filledExpertises,
            answers: filledAnswers,
          });
          if (!oldResults.hasInconsistency) {
            // eslint-disable-next-line no-use-before-define
            saveExpertiseMutate.mutateAsync({ expertises: filledExpertises, answers: filledAnswers });
          } else {
            setOldReportUrl(oldResults.oldReportUrl!);
            checkOldDamagesModalOnOpen();
          }
        }
      }
    },
  });
  const saveExpertiseMutate = useMutation<void, any, ExpertiseSaveData>(
    async (data: ExpertiseSaveData) => {
      await Api.Expertise.saveExpertiseBodyParts({
        customerId: customer!.id,
        expertiseBodyDamagePartTypeId: ExpertiseBodyDamagePartType.Chassis,
        bodyDamageAnswers: Object.entries(data.answers).map(([key, value]) => {
          return {
            answer: value,
            expertiseBodyQuestionId: parseInt(key, 10),
          };
        }),
        bodyDamageParts: Object.entries(data.expertises).map(([key, value]) => {
          return {
            bodyDamageValue: value,
            expertiseBodyDamagePartId: parseInt(key, 10),
          };
        }),
      });
    },
    {
      onSuccess: () => {
        onClose();
        toast({
          title: 'Başarı ile kayıt edildi',
          status: 'success',
          isClosable: true,
        });
      },
      onError: (err) => {
        console.error(err);
        toast({
          title: 'Kayıt sırasında hata oluştu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );

  const checkOldDamageMutate = useMutation<CheckOldBodyDamagesResponse, any, ExpertiseSaveData>(
    async (data: ExpertiseSaveData) => {
      const response = await Api.Expertise.checkOldBodyDamages({
        customerId: customer!.id,
        expertiseBodyDamagePartTypeId: ExpertiseBodyDamagePartType.Chassis,
        bodyDamageAnswers: Object.entries(data.answers).map(([key, value]) => {
          return {
            answer: value,
            expertiseBodyQuestionId: parseInt(key, 10),
          };
        }),
        bodyDamageParts: Object.entries(data.expertises).map(([key, value]) => {
          return {
            bodyDamageValue: value,
            expertiseBodyDamagePartId: parseInt(key, 10),
          };
        }),
      });
      return response.data;
    },
    {
      onError: (err) => {
        console.error(err);
        toast({
          title: 'Kontrol sırasında hata oluştu',
          status: 'error',
          isClosable: true,
        });
      },
    },
  );
  const { setFieldValue, values, handleSubmit } = formik;
  const onBodyworkExpertiseChanged = (
    expertiseKeyName: ExpertiseChassisCarBodyPartName,
    expertiseBodyworkExpertiseState: ExpertiseChassisDamageType,
  ) => {
    setFieldValue(`expertises.${expertiseKeyName}`, expertiseBodyworkExpertiseState);
  };

  const expertiseImageUrl = getExpertiseBackroundImageUrl(carBodyType);

  useEffect(() => {
    refetchExpertises();
    expertisesTemplateRefetch();
  }, [isOpen]);

  useEffect(() => {
    expertiseSocket.on('expertiseBodyDamageChanged', (data) => {
      if (data.customer.id === customer?.id) {
        refetchExpertises();
      }
    });
  }, []);

  const showLoading = !customer || !expertiseTemplates || isExpertisesTemplateLoading || isExpertisesLoading || !isOpen;

  return (
    <Portal>
      <Modal isOpen={checkOldDamagesModalIsOpen} onClose={checkOldDamagesModalOnClose} size="md" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Uyarı</ModalHeader>
          <ModalCloseButton />
          <ModalBody>Geçmiş ekspertiz raporuna göre boya değişenlerde değişiklik tespit edilmiştir.</ModalBody>
          <ModalFooter justifyContent="space-between">
            <Button
              leftIcon={<Icon as={DownloadIcon} />}
              colorScheme="teal"
              onClick={() => {
                window.open(oldReportUrl);
              }}
            >
              Geçmiş Ekspertiz Raporu
            </Button>
            <Button
              onClick={() => {
                handleSubmit();
              }}
            >
              Kaydet
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Drawer isOpen={isOpen} onClose={onClose} size="xl">
        <DrawerOverlay />
        <DrawerContent overflow="auto">
          <form noValidate onSubmit={handleSubmit}>
            <DrawerCloseButton disabled={showLoading || saveExpertiseMutate.isLoading} />
            <DrawerHeader>Direk ve Şasi Kontrolleri</DrawerHeader>

            <DrawerBody height="full">
              {showLoading ? (
                <Center>
                  <LoadingText />
                </Center>
              ) : (
                <VStack gap={24} align="stretch">
                  <VStack>
                    <Box width="100%" position="relative" maxWidth="450px">
                      <Image src={expertiseImageUrl} fit="fill" width="100%" />
                      <Box position="absolute" top="0" left="0" right="0" bottom="0">
                        {expertiseTemplates.expertiseBodyDamageParts.map((expertiseTemplate) => {
                          const partInfo =
                            inputPositionTable[expertiseTemplate.displayId as ExpertiseChassisCarBodyPartName];
                          return (
                            <ExpertiseBodyworkExpertiseChassisStateSelector
                              key={`ebw${expertiseTemplate.displayId}`}
                              expertiseKeyName={expertiseTemplate.id}
                              displayId={expertiseTemplate.displayId}
                              onBodyworkExpertiseChanged={onBodyworkExpertiseChanged}
                              value={values.expertises[expertiseTemplate.id] || ExpertiseChassisDamageType.Original}
                              left={partInfo?.left}
                              top={partInfo?.top}
                              bottom={partInfo?.bottom}
                              right={partInfo?.right}
                              label={expertiseTemplate.name}
                              isDisabled={isDisabled}
                            />
                          );
                        })}
                      </Box>
                    </Box>
                  </VStack>
                  <Box height="xs" />
                </VStack>
              )}
            </DrawerBody>

            <DrawerFooter>
              <Button
                variant="outline"
                mr={3}
                onClick={onClose}
                disabled={showLoading || saveExpertiseMutate.isLoading}
              >
                İptal
              </Button>
              {!isDisabled && (
                <Button type="submit" disabled={showLoading || saveExpertiseMutate.isLoading}>
                  Kaydet
                </Button>
              )}
            </DrawerFooter>
          </form>
        </DrawerContent>
      </Drawer>
    </Portal>
  );
};
