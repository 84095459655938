import { io } from 'socket.io-client';

export const customerSocket = io(`https://api.ekspertiz.occocar.com/customer`, {
  transports: ['websocket'],
  reconnection: true,
  'reconnectionDelay': 3000,
  'reconnectionDelayMax': 5000,
  'reconnectionAttempts': Infinity,
});

export const expertiseSocket = io(`https://api.ekspertiz.occocar.com/expertise`, {
  transports: ['websocket'],
  reconnection: true,
  'reconnectionDelay': 3000,
  'reconnectionDelayMax': 5000,
  'reconnectionAttempts': Infinity,
});

export const expertisePointSocket = io(`https://api.ekspertiz.occocar.com/expertise-point`, {
  transports: ['websocket'],
  reconnection: true,
  'reconnectionDelay': 3000,
  'reconnectionDelayMax': 5000,
  'reconnectionAttempts': Infinity,
});

export const toggleSocket = io(`https://api.ekspertiz.occocar.com/toggle`, {
  transports: ['websocket'],
  reconnection: true,
  'reconnectionDelay': 3000,
  'reconnectionDelayMax': 5000,
  'reconnectionAttempts': Infinity,
});
